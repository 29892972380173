import {Action, ReducerBuilder} from "redux-ts";
import {Reducer} from "platform/redux/Reducer";
import Platform from "platform/Platform";
import {ServiceType} from "kbd/enum/ServiceType";
import {BoardReduxState} from "kbd/core/redux/board/BoardReduxState";
import BoardEngine from "kbd/core/engine/BoardEngine";
import {
    ClearServerErrors,
    DoLoginNotifyUserAgreementType,
    DoResendEmail,
    DoResendEmailType,
    DoSocialLoginType,
    DoSubmitChangePasswordFormType,
    DoSubmitForgotPasswordForm,
    DoSubmitForgotPasswordFormPayload,
    DoSubmitForgotPasswordFormType,
    DoSubmitLoginForm,
    DoSubmitLoginFormPayload,
    DoSubmitLoginFormType, DoSubmitLoginTokenType,
    DoSubmitResetPasswordForm,
    DoSubmitResetPasswordFormPayload,
    DoSubmitResetPasswordFormType,
    DoSubmitSignUpForm,
    DoSubmitSignUpFormPayload,
    DoSubmitSignUpFormType,
    DoVerifyResetPassword,
    DoVerifyResetPasswordPayload,
    DoVerifyResetPasswordType,
    GetResetPasswordVerificationCodeType,
    SetBoardForm,
    SetBoardFormPayload,
    SetBoardFormSubmitting,
    SetBoardFormSubmittingPayload,
    SetChangePasswordRequired,
    SetChangePasswordRequiredPayload,
    SetLoginPlatformType,
    SetLoginPlatformTypePayload,
    SetLoginPlatformTypes,
    SetLoginPlatformTypesPayload,
    SetMigrationDialog,
    SetMigrationDialogPayload,
    SetPasswordReset,
    SetPasswordResetPayload,
    SetResetLinkSendToEmail,
    SetResetLinkSendToEmailPayload,
    SetServerError,
    SetServerErrorPayload,
    SetSignUpCountries,
    SetSignUpCountriesPayload,
    SetSignUpSource,
    SetSignUpSourcePayload,
    SetTCLoginAgreement,
    SetTCLoginAgreementPayload,
    SetVerificationCodeIssued,
    SetVerificationCodeIssuedPayload,
    SetVerificationIsLimitReached,
    SetVerificationIsLimitReachedPayload,
    SetVerificationMethod,
    SetVerificationMethods,
    SetVerificationMethodsPayload,
    VerificationMethodPayload
} from "kbd/core/redux/board/BoardReduxActions";
import {TSMap} from "typescript-map";
import Utils from "platform/util/Utils";
import {ServerType} from "platform/enum/ServerType";
import {SetBrandPropsType} from "platform/redux/core/CoreActions";
import {ContactInfoSource} from "kbd/enum/ContactInfoSource";
import {VerificationMethodType} from "kbd/protocol/auth/VerificationMethodType";
import {LoginPlatformType} from "kbd/enum/LoginPlatformType";

export default class BoardReducer extends Reducer<BoardReduxState> {

    private static _instance: BoardReducer;

    public static instance(): BoardReducer {
        return this._instance || (this._instance = new this());
    }

    private constructor() {
        super();
        const boardEngine: BoardEngine = Platform.engine(ServiceType.Board);
        this._middlewareActions.set("@@router5/TRANSITION_START", boardEngine.onChangeRoute);
        this._middlewareActions.set(DoSocialLoginType, boardEngine.doSocialLogin);
        this._middlewareActions.set(SetBrandPropsType, boardEngine.doSetBrandProps);
        this._middlewareActions.set(DoSubmitSignUpFormType, boardEngine.doSubmitSignUpForm);
        this._middlewareActions.set(DoSubmitLoginFormType, boardEngine.doSubmitLoginForm);
        this._middlewareActions.set(DoSubmitLoginTokenType, boardEngine.doSubmitLoginToken);
        this._middlewareActions.set(DoSubmitForgotPasswordFormType, boardEngine.doSubmitForgotPasswordForm);
        this._middlewareActions.set(DoSubmitResetPasswordFormType, boardEngine.doSubmitResetPasswordForm);
        this._middlewareActions.set(DoSubmitChangePasswordFormType, boardEngine.doSubmitChangePasswordForm);
        this._middlewareActions.set(DoResendEmailType, boardEngine.doResendEmail);
        this._middlewareActions.set(DoLoginNotifyUserAgreementType, boardEngine.doLoginNotifyUserAgreement);
        this._middlewareActions.set(GetResetPasswordVerificationCodeType, boardEngine.onGetResetPasswordVerificationCode);
        this._middlewareActions.set(DoVerifyResetPasswordType, boardEngine.doVerifyResetPassword);
    }

    public get name(): string {
        return "board";
    }

    protected setup(builder: ReducerBuilder<BoardReduxState>): void {
        builder
            .init({
                signUpSource: ContactInfoSource.Manual,
                countries: [],
                serverType: ServerType.TradingReal,
                verificationMethodType: VerificationMethodType.Email,
                verificationMethods: [],
                loginTypes: [],
                loginType: LoginPlatformType.Email,
                form: {
                    signUpPassword: ""
                },
                serverErrors: new TSMap(),
                tcAgreement: {}
            })
            .handle(SetSignUpSource, (state: BoardReduxState, {payload}: Action<SetSignUpSourcePayload>) => {
                return Object.assign({}, state, {
                    signUpSource: payload.signUpSource
                });
            })
            .handle(SetSignUpCountries, (state: BoardReduxState, {payload}: Action<SetSignUpCountriesPayload>) => {
                this._logger.debug("Set sign up countries: " + payload.countries.length);
                return Object.assign({}, state, {
                    countries: payload.countries
                });
            })
            .handle(SetLoginPlatformTypes, (state: BoardReduxState, {payload}: Action<SetLoginPlatformTypesPayload>) => {
                return Object.assign({}, state, {
                    loginTypes: payload.loginTypes
                });
            })
            .handle(SetLoginPlatformType, (state: BoardReduxState, {payload}: Action<SetLoginPlatformTypePayload>) => {
                return Object.assign({}, state, {
                    loginType: payload.loginType
                });
            })
            .handle(SetBoardForm, (state: BoardReduxState, {payload}: Action<SetBoardFormPayload>) => {
                return Object.assign({}, state, {
                    form: {
                        ...state.form,
                        ...payload.form
                    }
                });
            })
            .handle(SetServerError, (state: BoardReduxState, {payload}: Action<SetServerErrorPayload>) => {
                const newState: BoardReduxState = Utils.merge({}, state);
                newState.serverErrors.set(payload.fieldType, payload.error);
                return newState;
            })
            .handle(ClearServerErrors, (state: BoardReduxState, action: Action<any>) => {
                const newState: BoardReduxState = Utils.merge({}, state);
                newState.serverErrors = new TSMap();
                return newState;
            })
            .handle(SetResetLinkSendToEmail, (state: BoardReduxState, {payload}: Action<SetResetLinkSendToEmailPayload>) => {
                return Object.assign({}, state, {
                    resetLinkSent: payload.sent
                });
            })
            .handle(SetPasswordReset, (state: BoardReduxState, {payload}: Action<SetPasswordResetPayload>) => {
                return Object.assign({}, state, {
                    passwordReset: payload.reset
                });
            })
            .handle(SetBoardFormSubmitting, (state: BoardReduxState, {payload}: Action<SetBoardFormSubmittingPayload>) => {
                return Object.assign({}, state, {
                    submitting: payload.submitting
                });
            })
            .handle(SetChangePasswordRequired, (state: BoardReduxState, {payload}: Action<SetChangePasswordRequiredPayload>) => {
                return Object.assign({}, state, {
                    changePasswordRequired: payload.required
                });
            })
            .handle(DoSubmitSignUpForm, (state: BoardReduxState,{payload} : Action<DoSubmitSignUpFormPayload>) => {
                return Object.assign({}, state, {
                    submitting: true,
                    form: {
                        ...state.form,
                        ...payload.form
                    }
                });
            })
            .handle(DoSubmitLoginForm, (state: BoardReduxState, {payload}: Action<DoSubmitLoginFormPayload>) => {
                return Object.assign({}, state, {
                    submitting: true,
                    form: {
                        ...state.form,
                        ...payload.form,
                        ...{
                            email: payload.form.username
                        }
                    }
                });
            })
            .handle(DoSubmitForgotPasswordForm, (state: BoardReduxState, {payload}: Action<DoSubmitForgotPasswordFormPayload>) => {
                return Object.assign({}, state, {
                    submitting: true,
                    form: {
                        ...state.form,
                        ...payload.form
                    }
                });
            })
            .handle(DoVerifyResetPassword, (state: BoardReduxState, {payload}: Action<DoVerifyResetPasswordPayload>) => {
                return Object.assign({}, state, {
                    submitting: true,
                    form: {
                        ...state.form,
                        ...payload.form
                    }
                });
            })
            .handle(DoSubmitResetPasswordForm, (state: BoardReduxState, action: Action<DoSubmitResetPasswordFormPayload>) => {
                return Object.assign({}, state, {
                    submitting: true
                });
            })
            .handle(DoResendEmail, (state: BoardReduxState, action: Action<any>) => {
                return Object.assign({}, state, {
                    submitting: true
                });
            })
            .handle(SetTCLoginAgreement, (state: BoardReduxState, {payload}: Action<SetTCLoginAgreementPayload>) => {
                return Object.assign({}, state, {
                    tcAgreement: payload.tcAgreement
                });
            })
            .handle(SetVerificationMethods, (state: BoardReduxState, {payload}: Action<SetVerificationMethodsPayload>) => {
                return Object.assign({}, state, {
                    verificationMethods: payload.methods
                });
            })
            .handle(SetVerificationMethod, (state: BoardReduxState, {payload}: Action<VerificationMethodPayload>) => {
                return Object.assign({}, state, {
                    verificationMethodType: payload.method
                });
            })
            .handle(SetVerificationCodeIssued, (state: BoardReduxState, {payload}: Action<SetVerificationCodeIssuedPayload>) => {
                return Object.assign({}, state, {
                    verificationCodeIssued: payload.issued
                });
            })
            .handle(SetMigrationDialog, (state: BoardReduxState, {payload}: Action<SetMigrationDialogPayload>) => {
                return Object.assign({}, state, {
                    migrationDialogType: payload.dialogType,
                    forkDaysLeft: payload.daysLeft,
                    forkSignUpThreshold: payload.signUpThreshold
                });
            })
            .handle(SetVerificationIsLimitReached, (state: BoardReduxState, {payload}: Action<SetVerificationIsLimitReachedPayload>) => {
                return Object.assign({}, state, {
                    isLimitReached: payload.IsLimitReached
                });
            });
    }
}
