import {Logger, LogLevel} from "platform/logger/Logger";
import {ConfigurationLoader} from "platform/configuration/ConfigurationLoader";
import Utils from "platform/util/Utils";
import {LangCode} from "platform/enum/LangCode";
import {Configuration} from "kbd/core/configuration/Configuration";
import {Http} from "platform/network/http/Http";

export default class ConfigManager {

    private static _logger: Logger = Logger.Of("ConfigManager");
    private static _loader: ConfigurationLoader<Configuration>;
    private static _config: Configuration = {
        version: "1.0.0",
        logLevel: LogLevel.DEBUG as any,
        brand: "-",
        supportedLanguages: [LangCode.EN],
        products: {
            kyc: {
                navigation: {
                    pages: []
                }
            },
            board: {
                marketingContent: [],
                signup: {
                    fields: [],
                    validation: {
                        password: []
                    }
                },
                ResetPassword: []
            }
        },
        deposit: {
            topUpPackageTrades: {},
        }
    };

    private constructor() {
    }

    public static loader(): ConfigurationLoader<Configuration> {
        if (Utils.isNull(this._loader)) {
            this._loader = {
                load(): Promise<Configuration> {
                    return new Promise<Configuration>(
                        (resolve, reject) => {
                            Http.getJson("resources/configuration/config.json").then((config: Configuration) => {
                                ConfigManager._logger.info("Configuration loaded");
                                try {
                                    const result: Configuration = Object.assign({}, ConfigManager._config, config);
                                    if (result.servers) {
                                        const parts: string [] = window.location.hostname?.split(".");
                                        const domain: string = `${parts[parts.length - 2]}.${parts[parts.length - 1]}`;
                                        const placeholder: string = "{@domain}";
                                        Object.keys(result.servers).forEach((key: string) => {
                                            const url: string = result.servers[key];
                                            if (url?.indexOf(placeholder) > 0) {
                                                result.servers[key] = url?.replace(placeholder, domain);
                                            }
                                        });
                                    }
                                    ConfigManager._config = result;
                                    resolve(result);
                                } catch (e) {
                                    ConfigManager._logger.warn("Failed to merge configuration. " + JSON.stringify(e) + " Going to use hardcoded configuration");
                                    resolve(ConfigManager._config);
                                }
                            }).catch((e) => {
                                ConfigManager._logger.warn("Failed to load configuration: " + JSON.stringify(e));
                                reject();
                            });
                        }
                    );
                }
            };
        }
        return this._loader;
    }
}
