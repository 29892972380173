import Platform from "platform/Platform";
import {
    Configuration,
    KycProductConfig,
    OnBoardProductConfig
} from "kbd/core/configuration/Configuration";
import {ProductType} from "kbd/entry/ProductType";
import {ComplianceFlowType} from "kbd/enum/ComplianceFlowType";

export class ConfigUtil {

    private constructor() {}

    public static configBoard(complianceFlow?: ComplianceFlowType): OnBoardProductConfig {
        const config: Configuration = Platform.config<Configuration>();
        const base: OnBoardProductConfig = config.products[ProductType.Board] as OnBoardProductConfig || {};
        const compliance: OnBoardProductConfig = config.products[`${ProductType.Board}_${complianceFlow}`] as OnBoardProductConfig || {};
        return Object.assign({}, base, compliance);
    }

    public static UseAddressBarDomain = (url: string): string => {
        if (url && Platform.config<Configuration>().useAddressBarDomain) {
            const parts: string [] = window.location.hostname?.split(".");
            const Url: URL = new URL(url);
            const hostnameParts: string [] = Url.hostname.split(".");
            hostnameParts[hostnameParts.length - 2] = parts[parts.length - 2];
            hostnameParts[hostnameParts.length - 1] = parts[parts.length - 1];
            Url.hostname = hostnameParts.join(".");
            return Url.toString();
        }
        return url;
    }
}
