import {TranslationKey} from "kbd/enum/TranslationKey";
import Translations from "platform/translation/Translations";

enum ErrorCode {

    GeneralError = 1,
    InvalidUsernameOrPassword = 10001,
    EmailAddressNotFound = 10002,
    PasswordsNotMatch = 10003,

    // Sign Up error codes with prefix 10
    INVALID_API_KEY = 101,
    DUPLICATE_LEAD = 102,
    INVALID_DATA = 103,
    INVALID_MESSAGE_FORMAT = 104,
    UNEXPECTED_INTERNAL_ERROR = 105,
    OTHER = 106,
    DUPLICATE_EID = 107,
    COUNTRY_NOT_ALLOWED = 108,
    INVALID_EMAIL = 109,
    TAC_NOT_APPROVED = 1010,
    PRIVACY_NOTICE_NOT_APPROVED = 1011,
    FUNNEL_NOT_FOUND = 1012,
    ACTIVATION_CODE_INVALID = 1013,
    LEAD_EVENT_FOR_ACTIVATION_NOT_FOUND_OR_EXPIRED =  1014,
    PLATFORM_NOT_SUPPORTED =  1015,
    CAPTCHA_VALIDATION_NOT_PASSED = 1016,
    EMAIL_RESEND_ATTEMPS_THRESHOLD_WAS_REACHED = 1017,
    EMAIL_ADDRESS_ALREADY_EXIST = 1050, // Custom client error code,
    SIGN_UP_ERROR_FACEBOOK = 1051, // Custom client error code
    SIGN_UP_ERROR_GOOGLE = 1052, // Custom client error code
    SIGN_UP_ERROR_FACEBOOK_FAILED = 1053, // Custom client error code
    SIGN_UP_ERROR_GOOGLE_FAILED = 1054, // Custom client error code
}

namespace ErrorCode {

    export function trKey(errorCode: ErrorCode): TranslationKey {
        let errorTrKey: string = "server.error.code." + errorCode;
        if (!Translations.hasKey(errorTrKey)) {
            errorTrKey = "server.error.general";
        }
        return errorTrKey as TranslationKey;
    }
}

export {ErrorCode};
