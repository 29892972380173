import {TSMap} from "typescript-map";
import {CountryInfo} from "platform/protocol/common/CountryInfo";

export class BoardState {

    private static _instance: BoardState;
    private _phoneCodePerCountry: TSMap<string, CountryInfo> = new TSMap();

    private constructor() {
    }

    public static instance(): BoardState {
        return this._instance || (this._instance = new this());
    }

    public addCountry(ci: CountryInfo): void {
        this._phoneCodePerCountry.set(ci.PhoneCode, ci);
    }

    public getCountryByPhoneCode(phoneCode: string): CountryInfo {
        return this._phoneCodePerCountry.get(phoneCode);
    }
}
