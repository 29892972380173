import {load, ReCaptchaInstance} from "recaptcha-v3";
import {FormikErrors, FormikValues} from "formik";
import Utils from "platform/util/Utils";
import {FieldType} from "kbd/enum/FieldType";
import {CountryInfo} from "platform/protocol/common/CountryInfo";
import {BoardState} from "kbd/core/state/BoardState";
import Platform from "platform/Platform";
import {ServiceType} from "kbd/enum/ServiceType";
import {Configuration, OnBoardProductConfig, PasswordRuleConfig} from "kbd/core/configuration/Configuration";
import {ProductType} from "kbd/entry/ProductType";
import {FormPasswordError} from "kbd/component/primitive/FormPasswordInput";

export class BoardUtil {

    private constructor() {}

    public static async generateCaptchaToken(key: string): Promise<string> {
        const answer: [any, ReCaptchaInstance] = await Utils.to(load(key, {
            autoHideBadge: true
        }));
        if (answer[0]) {
            return Promise.resolve(null);
        }
        return new Promise<string>((resolve) => {
            answer[1].execute("registration").then((token: string) => {
                resolve(token);
            }).catch(() => {
                resolve(null);
            });
        });
    }

    public static validateName(values: FormikValues, errors: FormikErrors<any>): void {
        const productConfig: OnBoardProductConfig = Platform.config<Configuration>().products[ProductType.Board] as OnBoardProductConfig;
        const regExp: RegExp = new RegExp(productConfig.signup.validation.name);
        const name: string = values[FieldType.FullName];
        if (Utils.isEmpty(name) || !regExp.test(name)) {
            errors[FieldType.FullName] = "Invalid";
        }
    }

    public static validateEmail(values: FormikValues, errors: FormikErrors<any>): void {
        const email: string = values[FieldType.Email];
        const regExp: RegExp = new RegExp("(?!.*?[._%+-]{2,})(?=[a-zA-Z0-9@._%+-]{6,254}$)(^[a-zA-Z0-9])[a-zA-Z0-9._%+-]{1,64}([^._%+-]{1,})@(?:[a-zA-Z0-9-]{1,63}\\.){1,8}[a-zA-Z]{2,63}");
        if (Utils.isEmpty(email) || !regExp.test(email)) {
            errors[FieldType.Email] = "Invalid";
        }
    }

    public static validateUsername(values: FormikValues, errors: FormikErrors<any>): void {
        const username: string = values[FieldType.Username];
        if (Utils.isEmpty(username)) {
            errors[FieldType.Username] = "Invalid";
        }
    }

    public static validateToken(values: FormikValues, errors: FormikErrors<any>): void {
        const token: string = values[FieldType.Token]?.replace(/\s/g, "");
        if (Utils.isEmpty(token) || !parseInt(token) || token?.length !== 20) {
            errors[FieldType.Token] = "Invalid";
        }
    }

    public static async validatePhone(values: FormikValues, errors: FormikErrors<any>): Promise<void> {
        const countryCode: string = values[FieldType.SignUpCountryCode];
        const phone: string = values[FieldType.PhoneNumber];
        if (Utils.isEmpty(countryCode) || Utils.isEmpty(phone)) {
            errors[FieldType.PhoneNumber] = "Invalid";
        } else {
            const libphonenumber = await import('libphonenumber-js');
            const boardState: BoardState = Platform.state(ServiceType.Board);
            const ci: CountryInfo = boardState.getCountryByPhoneCode(countryCode);
            if (Utils.isNull(ci) || !libphonenumber.isValidNumber(phone, ci.Code as any)) {
                errors[FieldType.PhoneNumber] = "Invalid";
            }
        }
    }

    public static validatePassword(fieldType: FieldType, values: FormikValues, errors: FormikErrors<any>): void {
        const password: string = values[fieldType];
        if (Utils.isEmpty(password)) {
            errors[fieldType] = "Invalid";
        }
    }

    public static validateSignUpPassword(values: FormikValues, errors: FormikErrors<any>): void {
        const productConfig: OnBoardProductConfig = Platform.config<Configuration>().products[ProductType.Board] as OnBoardProductConfig;
        const rules = productConfig.signup.validation.password;
        if (Utils.isArrayNotEmpty(rules)) {
            const password: string = values[FieldType.SignUpPassword];
            const Errors: FormPasswordError[] = [];
            rules.forEach((rule: PasswordRuleConfig) => {
                const regExp: RegExp = new RegExp(rule.pattern);
                Errors.push({
                    trKey: "board.signup.password.error.RegEx" + rule.index,
                    rule: regExp.test(password) === rule.valid ? null : rule
                });
            });
            if (Errors.some(({rule}) => Utils.isNotNull(rule))) {
                errors[FieldType.SignUpPassword] = Errors as any;
            }
        }
    }

    public static validateResetPassword(values: FormikValues, errors: FormikErrors<any>): void {
        const productConfig: OnBoardProductConfig = Platform.config<Configuration>().products[ProductType.Board] as OnBoardProductConfig;
        const rules = productConfig.ResetPassword;
        BoardUtil.validatePassword(FieldType.NewPassword, values, errors);
        BoardUtil.validatePassword(FieldType.ConfirmPassword, values, errors);

        if (Utils.isArrayNotEmpty(rules)) {
            const password: string = values[FieldType.NewPassword];
            const Errors: FormPasswordError[] = [];
            rules.forEach((rule: PasswordRuleConfig) => {
                const regExp: RegExp = new RegExp(rule.pattern);
                Errors.push({
                    trKey: "board.signup.password.error.RegEx" + rule.index,
                    rule: regExp.test(password) === rule.valid ? null : rule
                });
            });

            if (Errors.some(({rule}) => Utils.isNotNull(rule))) {
                errors[FieldType.NewPassword] = Errors as any;
            }
        }
        if (Utils.isObjectEmpty(errors)) {
            const newPassword: string = values[FieldType.NewPassword];
            const confirmPassword: string = values[FieldType.ConfirmPassword];
            if (newPassword !== confirmPassword) {
                errors[FieldType.ConfirmPassword] = "Invalid";
            }
        }
    }

    public static validateReferralCode(values: FormikValues, errors: FormikErrors<any>): void {
        const haveReferralCode: string = values[FieldType.HaveReferralCode];
        const referralCode: string = values[FieldType.ReferralCode] || "";
        if (Utils.parseBoolean(haveReferralCode) && !referralCode.match(/(^[Tt]{0,1}[0-9]{4}$)/)) {
            errors[FieldType.ReferralCode] = "Invalid";
        }
    }

    public static hasSignUpField(fieldType: FieldType): boolean {
        const productConfig: OnBoardProductConfig = Platform.config<Configuration>().products[ProductType.Board] as OnBoardProductConfig;
        return productConfig.signup.fields.indexOf(fieldType) > -1;
    }
}
