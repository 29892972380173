enum ContactInfoSource {

    Manual = "MANUAL",
    Facebook = "FACEBOOK",
    Google = "GOOGLE"
}

namespace ContactInfoSource {

    export function isSocial(source: ContactInfoSource): boolean {
        return source === ContactInfoSource.Google || source === ContactInfoSource.Facebook;
    }
}

export {
    ContactInfoSource
};
