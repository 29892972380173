import {ActionCreatorDefinition, createAction} from "redux-ts";
import {
    ChangePasswordFormState,
    ForgotPasswordFormState,
    LoginFormState,
    ResetPasswordFormState,
    SignUpFormState, TcAgreementState, VerificationFormState
} from "kbd/core/redux/board/BoardReduxState";
import {BoardFormType} from "kbd/enum/BoardFormType";
import {FieldType} from "kbd/enum/FieldType";
import {ServerError} from "kbd/core/common/ServerError";
import {CountryInfo} from "platform/protocol/common/CountryInfo";
import {ContactInfoSource} from "kbd/enum/ContactInfoSource";
import {VerificationMethodType} from "kbd/protocol/auth/VerificationMethodType";
import {VerificationMethod} from "kbd/protocol/auth/VerificationMethod";
import {MigrationDialogType} from "kbd/enum/MigrationDialogType";
import {LoginPlatformType} from "kbd/enum/LoginPlatformType";

export const SetLoginPlatformTypesType: string =  "SetLoginPlatformTypes";
export const SetLoginPlatformTypeType: string =  "SetLoginPlatformType";
export const SetBoardFormType: string =  "SetBoardForm";
export const SetServerErrorType: string =  "SetServerError";
export const ClearServerErrorsType: string =  "ClearServerErrors";
export const SetResetLinkSendToEmailType: string =  "SetResetLinkSendToEmail";
export const SetPasswordResetType: string =  "SetPasswordReset";
export const SetBoardFormSubmittingType: string =  "SetBoardFormSubmitting";
export const SetSignUpSourceType: string =  "SetSignUpSource";
export const DoSocialLoginType: string =  "DoSocialLogin";
export const DoSubmitSignUpFormType: string =  "DoSubmitSignUpForm";
export const DoSubmitLoginFormType: string =  "DoSubmitLoginForm";
export const DoSubmitLoginTokenType: string =  "DoSubmitLoginToken";
export const DoSubmitForgotPasswordFormType: string =  "DoSubmitForgotPasswordForm";
export const DoSubmitResetPasswordFormType: string =  "DoSubmitResetPasswordForm";
export const DoSubmitChangePasswordFormType: string =  "DoSubmitChangePasswordForm";
export const DoResendEmailType: string =  "DoResendEmail";
export const SetChangePasswordRequiredType: string =  "SetChangePasswordRequired";
export const SetTCLoginAgreementType: string =  "SetTCLoginAgreement";
export const DoLoginNotifyUserAgreementType: string =  "DoLoginNotifyUserAgreement";
export const SetSignUpCountriesType: string =  "SetSignUpCountries";
export const DoVerifyResetPasswordType: string =  "DoVerifyResetPassword";
export const SetVerificationMethodsType: string = "SetVerificationMethods";
export const GetResetPasswordVerificationCodeType: string = "GetResetPasswordVerificationCode";
export const SetVerificationMethodType: string = "SetVerificationMethod";
export const SetVerificationCodeIssuedType: string = "SetVerificationCodeIssued";
export const SetMigrationDialogType: string = "SetMigrationDialog";
export const SetVerificationIsLimitReachedType: string = "SetVerificationIsLimitReached";

export interface SetLoginPlatformTypesPayload {

    loginTypes: LoginPlatformType[];
}

export interface SetLoginPlatformTypePayload {

    loginType: LoginPlatformType;
}

export interface SetBoardFormPayload {

    form: SignUpFormState | LoginFormState | ForgotPasswordFormState | ResetPasswordFormState;
}

export interface SetServerErrorPayload {

    fieldType: FieldType;
    error?: ServerError;
}

export interface SetVerificationMethodsPayload {

    methods: VerificationMethod[];
}

export interface VerificationMethodPayload {

    method: VerificationMethodType;
    ResendVerificationCode?: boolean;
}

export interface SetResetLinkSendToEmailPayload {

    sent: boolean;
}

export interface SetPasswordResetPayload {

    reset: boolean;
}

export interface SetBoardFormSubmittingPayload {

    submitting: boolean;
}

export interface SetSignUpSourcePayload {

    signUpSource: ContactInfoSource;
}

export interface DoSocialLoginPayload {

    socialSource: ContactInfoSource;
    formType: BoardFormType;
}

export interface DoSubmitSignUpFormPayload {

    form: SignUpFormState;
}

export interface DoSubmitLoginFormPayload {

    form: LoginFormState;
}

export interface DoSubmitLoginTokenPayload {

    token: string;
}

export interface DoSubmitForgotPasswordFormPayload {

    form: ForgotPasswordFormState;
}

export interface DoSubmitResetPasswordFormPayload {

    form: ResetPasswordFormState;
}

export interface DoSubmitChangePasswordFormPayload {

    form: ChangePasswordFormState;
}

export interface SetChangePasswordRequiredPayload {

    required: boolean;
}

export interface SetTCLoginAgreementPayload {

    tcAgreement: TcAgreementState;
}

export interface SetSignUpCountriesPayload {

    countries: CountryInfo[];
}

export interface DoVerifyResetPasswordPayload {

    form: VerificationFormState;
}

export interface SetVerificationCodeIssuedPayload {

    issued: boolean;
}

export interface SetMigrationDialogPayload {

    dialogType: MigrationDialogType;
    daysLeft: number;
    signUpThreshold: string;
}

export interface SetVerificationIsLimitReachedPayload {

    IsLimitReached: boolean;
}

export const SetLoginPlatformTypes: ActionCreatorDefinition<SetLoginPlatformTypesPayload, any> = createAction<SetLoginPlatformTypesPayload>(SetLoginPlatformTypesType);
export const SetLoginPlatformType: ActionCreatorDefinition<SetLoginPlatformTypePayload, any> = createAction<SetLoginPlatformTypePayload>(SetLoginPlatformTypeType);
export const SetBoardForm: ActionCreatorDefinition<SetBoardFormPayload, any> = createAction<SetBoardFormPayload>(SetBoardFormType);
export const SetServerError: ActionCreatorDefinition<SetServerErrorPayload, any> = createAction<SetServerErrorPayload>(SetServerErrorType);
export const ClearServerErrors: ActionCreatorDefinition<any, any> = createAction<any>(ClearServerErrorsType);
export const SetResetLinkSendToEmail: ActionCreatorDefinition<SetResetLinkSendToEmailPayload, any> = createAction<SetResetLinkSendToEmailPayload>(SetResetLinkSendToEmailType);
export const SetPasswordReset: ActionCreatorDefinition<SetPasswordResetPayload, any> = createAction<SetPasswordResetPayload>(SetPasswordResetType);
export const SetBoardFormSubmitting: ActionCreatorDefinition<SetBoardFormSubmittingPayload, any> = createAction<SetBoardFormSubmittingPayload>(SetBoardFormSubmittingType);
export const SetSignUpSource: ActionCreatorDefinition<SetSignUpSourcePayload, any> = createAction<SetSignUpSourcePayload>(SetSignUpSourceType);
export const DoSocialLogin: ActionCreatorDefinition<DoSocialLoginPayload, any> = createAction<DoSocialLoginPayload>(DoSocialLoginType);
export const DoSubmitSignUpForm: ActionCreatorDefinition<DoSubmitSignUpFormPayload, any> = createAction<DoSubmitSignUpFormPayload>(DoSubmitSignUpFormType);
export const DoSubmitLoginForm: ActionCreatorDefinition<DoSubmitLoginFormPayload, any> = createAction<DoSubmitLoginFormPayload>(DoSubmitLoginFormType);
export const DoSubmitLoginToken: ActionCreatorDefinition<DoSubmitLoginTokenPayload, any> = createAction<DoSubmitLoginTokenPayload>(DoSubmitLoginTokenType);
export const DoSubmitForgotPasswordForm: ActionCreatorDefinition<DoSubmitForgotPasswordFormPayload, any> = createAction<DoSubmitForgotPasswordFormPayload>(DoSubmitForgotPasswordFormType);
export const DoSubmitResetPasswordForm: ActionCreatorDefinition<DoSubmitResetPasswordFormPayload, any> = createAction<DoSubmitResetPasswordFormPayload>(DoSubmitResetPasswordFormType);
export const DoSubmitChangePasswordForm: ActionCreatorDefinition<DoSubmitChangePasswordFormPayload, any> = createAction<DoSubmitChangePasswordFormPayload>(DoSubmitChangePasswordFormType);
export const DoResendEmail: ActionCreatorDefinition<any, any> = createAction<any>(DoResendEmailType);
export const SetChangePasswordRequired: ActionCreatorDefinition<SetChangePasswordRequiredPayload, any> = createAction<SetChangePasswordRequiredPayload>(SetChangePasswordRequiredType);
export const SetTCLoginAgreement: ActionCreatorDefinition<SetTCLoginAgreementPayload, any> = createAction<SetTCLoginAgreementPayload>(SetTCLoginAgreementType);
export const DoLoginNotifyUserAgreement: ActionCreatorDefinition<any, any> = createAction<any>(DoLoginNotifyUserAgreementType);
export const SetSignUpCountries: ActionCreatorDefinition<SetSignUpCountriesPayload, any> = createAction<SetSignUpCountriesPayload>(SetSignUpCountriesType);
export const DoVerifyResetPassword: ActionCreatorDefinition<DoVerifyResetPasswordPayload, any> = createAction<DoVerifyResetPasswordPayload>(DoVerifyResetPasswordType);
export const SetVerificationMethods: ActionCreatorDefinition<SetVerificationMethodsPayload, any> = createAction<SetVerificationMethodsPayload>(SetVerificationMethodsType);
export const GetResetPasswordVerificationCode: ActionCreatorDefinition<VerificationMethodPayload, any> = createAction<VerificationMethodPayload>(GetResetPasswordVerificationCodeType);
export const SetVerificationMethod: ActionCreatorDefinition<VerificationMethodPayload, any> = createAction<VerificationMethodPayload>(SetVerificationMethodType);
export const SetVerificationCodeIssued: ActionCreatorDefinition<SetVerificationCodeIssuedPayload, any> = createAction<SetVerificationCodeIssuedPayload>(SetVerificationCodeIssuedType);
export const SetMigrationDialog: ActionCreatorDefinition<SetMigrationDialogPayload, any> = createAction<SetMigrationDialogPayload>(SetMigrationDialogType);
export const SetVerificationIsLimitReached: ActionCreatorDefinition<SetVerificationIsLimitReachedPayload, any> = createAction<SetVerificationIsLimitReachedPayload>(SetVerificationIsLimitReachedType);
