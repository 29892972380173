import * as Bowser from "bowser";
import {WebWindow} from "platform/integration/win/WebWindow";
import Utils from "platform/util/Utils";
import {TSMap} from "typescript-map";
import {WinType} from "platform/integration/win/WinType";

export default class WebUtil {

    private static _win: WebWindow;
    private static _intervals: TSMap<Window | HTMLIFrameElement, any> = new TSMap<Window | HTMLIFrameElement, any>();

    private constructor() {}

    public static BowserParsed(): Bowser.Parser.Parser {
        return Bowser.getParser(window.navigator.userAgent);
    }

    public static BowserDetails(): Bowser.Parser.Details {
        return WebUtil.BowserParsed()?.getBrowser();
    }

    public static window(): WebWindow {
        if (Utils.isNull(this._win)) {
            this._win = this.winProxy(window);
        }
        return this._win;
    }

    public static parentWindow(): WebWindow {
        const parentWindow: Window = this.detectParentWindow();
        if (Utils.isNotNull(parentWindow)) {
            return this.winProxy(parentWindow);
        }
        return null;
    }

    private static detectParentWindow(): Window {
        if (window !== window.parent) {
            return window.parent;
        } else if (window !== window.opener) {
            return window.opener;
        }
        return window.parent;
    }

    public static isFrame(): boolean {
        if (window !== window.parent) {
            return true;
        } else if (window !== window.opener) {
            return false;
        }
        return true;
    }

    public static webViewProxy(webView: any): WebWindow {
        return {

            type(): WinType {
                return WinType.NATIVE_MOBILE;
            },

            origin(): Window {
                return webView;
            },

            href(): string {
                return null;
            },

            postMessage(message: any, targetOrigin: string, transfer?: any[]): void {
                webView.postMessage(message);
            },

            addEventListener(type: string, listener: EventListenerOrEventListenerObject, options?: boolean | AddEventListenerOptions): void {

            },

            removeEventListener(type: string, listener: EventListenerOrEventListenerObject, options?: boolean | EventListenerOptions): void {

            },

            close(): void {

            }
        };
    }

    public static frameProxy(frame: HTMLIFrameElement): WebWindow {
        return {... this.winProxy(frame.contentWindow), ...{
                type(): WinType {
                    return WinType.FRAME;
                },
                close(): void {
                },
                href(): string {
                    return frame.getAttribute("src");
                },
                addEventListener(type: string, listener: EventListenerOrEventListenerObject, options?: boolean | AddEventListenerOptions): void {
                    WebUtil.addEventListener(frame, type, listener, options);
                },
                removeEventListener(type: string, listener: EventListenerOrEventListenerObject, options?: boolean | EventListenerOptions): void {
                    frame.removeEventListener(type, listener, options);
                },
            }};
    }

    public static winProxy(win: Window): WebWindow {
        return {

            type(): WinType {
                return WinType.WINDOW;
            },

            origin(): Window {
                return win;
            },

            href(): string {
                return win.location.href;
            },

            postMessage(message: any, targetOrigin: string, transfer?: any[]): void {
                win.postMessage(message, targetOrigin, transfer);
            },

            addEventListener(type: string, listener: EventListenerOrEventListenerObject, options?: boolean | AddEventListenerOptions): void {
                WebUtil.addEventListener(win, type, listener, options);
            },

            removeEventListener(type: string, listener: EventListenerOrEventListenerObject, options?: boolean | EventListenerOptions): void {
                win.removeEventListener(type, listener, options);
            },

            close(): void {
                win.close();
            }
        };
    }

    private static addEventListener(win: Window | HTMLIFrameElement, type: string, listener: EventListenerOrEventListenerObject, options?: boolean | AddEventListenerOptions): void {
        if (Utils.isNotNull(win)) {
            if (Utils.isNotNull(win.addEventListener)) {
                const interval: any = this._intervals.get(win);
                if (Utils.isNotNull(interval)) {
                    this._intervals.delete(win);
                    clearInterval(interval);
                }
                win.addEventListener(type, listener, options);
            } else {
                this._intervals.set(win, setInterval(() => {
                    this.addEventListener(win, type, listener, options);
                }, 50));
            }
        }
    }

    public static domainUrl(url?: string): string {
        return (Utils.isNotEmpty(url) ? url : window.location.href).match("^(?:https?://)?(?:[^@/\n]+@)?(?:www.)?([^:/?\n]+)((:[0-9]*)?)")[0];
    }

    public static inIosWebView(): boolean {
        const wk: any = (window as any).webkit;
        return Utils.isNotNull(wk) && Utils.isNotNull(wk.messageHandlers) && Utils.isNotNull(wk.messageHandlers.jsHandle) && Utils.isNotNull(wk.messageHandlers.jsHandle.postMessage);
    }

    public static inAndroidWebView(): boolean {
        const mjsi: any = (window as any).MobileJsInterface;
        return Utils.isNotNull(mjsi);
    }

    public static inRNWebView(): boolean {
        return Utils.isNotNull((window as any).ReactNativeWebView);
    }

    public static inWebView(): boolean {
        return WebUtil.inIosWebView() || WebUtil.inAndroidWebView() || WebUtil.inRNWebView();
    }

    public static isIPad(): boolean {
        return navigator.userAgent.match(/Mac/) && navigator.maxTouchPoints && navigator.maxTouchPoints > 2;
    }

    public static isIOS(): boolean {
        return WebUtil.isIPad()
            || /iPhone/i.test(navigator.userAgent)
            || /iPad/i.test(navigator.userAgent)
            || /iPod/i.test(navigator.userAgent);
    }

    public static isMobile(): boolean {
        return WebUtil.isIOS()
            || /Mobi/i.test(navigator.userAgent)
            || /Android/i.test(navigator.userAgent)
            || /webOS/i.test(navigator.userAgent)
            || /BlackBerry/i.test(navigator.userAgent)
            || /Windows Phone/i.test(navigator.userAgent)
            || /Opera Mini/i.test(navigator.userAgent)
            || /IEMobile/i.test(navigator.userAgent)
            || /WPDesktop/i.test(navigator.userAgent)
            || /TT-WRAPPER/i.test(navigator.userAgent)
            || WebUtil.inWebView();
    }

    public static isSafari(): boolean {
        return /^((?!chrome|android).)*safari/i.test(navigator?.userAgent);
    }

    public static urlParam(name: string, url?: string): string | null {
        const results: RegExpExecArray | null = new RegExp("[\?&]" + name + "=([^&#]*)").exec(url || (window.location ? window.location.href : null));
        if (results && results.length > 0) {
            return decodeURIComponent(results[1]);
        }
        return null;
    }

    public static href(html: string): string | null {
        const results: RegExpExecArray | null = new RegExp(/href="(.*?)"/g).exec(html);
        if (results && results.length > 0) {
            return results[1];
        }
        return null;
    }

    public static hasHtmlContent(v: string): boolean {
        if (Utils.isNotEmpty(v)) {
            return Utils.isNotNull(v.match("(.|\\r|\\n)*\\<[^>]+>(.|\\r|\\n)*"));
        }
        return false;
    }

    public static addGetParams(url: string, params: { [key: string]: string }): string {
        if (Utils.isNotEmpty(url)) {
            return url + (url.indexOf("?") > 0 ? "&" : "?") + Object.keys(params).map((key: string) => {
                return key + "=" + params[key];
            }).join("&");
        }
        return url;
    }

    public static addGetParam(url: string, name: string, value: string): string {
        if (Utils.isNotEmpty(url)) {
            return url + (url.indexOf("?") > 0 ? "&" : "?") + name + "=" + value;
        }
        return url;
    }

    public static deleteGetParam(url: string, name: string): string {
        if (Utils.isNotEmpty(url) && Utils.isNotEmpty(name)) {
            return url.replace(new RegExp('[?&]' + name + '=[^&#]*(#.*)?$'), '$1')
                .replace(new RegExp('([?&])' + name + '=[^&]*&'), '$1');
        }
        return url;
    }

    public static getParamsToObject(url: string = window.location.search): {[key: string]: string} {
        const result: any = {};
        if (Utils.isNotEmpty(url)) {
            const parts: string[] = url.split("?");
            const query: string = parts[1] || parts[0];
            if (Utils.isNotEmpty(query)) {
                const queryParameters: string[] = query.split("&");
                if (Utils.isArrayNotEmpty(queryParameters)) {
                    queryParameters.forEach((parameter: string) => {
                        const index: number = parameter.indexOf("=");
                        if (index > 0) {
                            const key: string = parameter.substring(0, index);
                            const value: string = parameter.substring(index + 1);
                            if (Utils.isNotEmpty(key) && Utils.isNotEmpty(value)) {
                                const k: string = key.trim();
                                const v: string = value.trim();
                                result[k] = v;
                            }
                        }
                    });
                }
            }
        }
        return result;
    }

    public static formatUrl(url: string, params: {[key: string]: string}): string {
        if (url && !Utils.isObjectEmpty(params)) {
            let result: string = url.split("?")[0] + "?";
            const keys: string[] = Object.keys(params);
            for (let i = 0; i < keys.length; i++) {
                const key: string = keys[i];
                result += (key + "=" + params[key]);
                if (i < keys.length - 1) {
                    result += "&";
                }
            }
            return result;
        }
        return url;
    }

    public static winCenter(width: number, height: number): string {
        const params: string[] = [];
        params.push("width=" + width);
        params.push("height=" + height);
        params.push(this.calculateWinLeft(width));
        params.push(this.calculateWinTop(height));
        return params.join(",");
    }

    public static calculateWinLeft(width: number): string {
        const dualScreenLeft = Utils.isNotNull(window.screenLeft) ? window.screenLeft : (window.screen as any).left;
        const wndWidth: number = window.innerWidth || document.documentElement.clientWidth || window.screen.width;
        return "left=" + Math.round((wndWidth - width) / 2 + dualScreenLeft);
    }

    public static calculateWinTop(height: number): string {
        const dualScreenTop: number = Utils.isNotNull(window.screenTop) ? window.screenTop : (window.screen as any).top;
        const wndHeight: number = window.innerHeight || document.documentElement.clientHeight || window.screen.height;
        return "top=" + Math.round((wndHeight - height) / 2 + dualScreenTop);
    }

    public static wndHeight(): number {
        return window.innerHeight || document.documentElement.clientHeight || window.screen.height;
    }

    public static isUrl(url: string): boolean {
        const sUrls: string = decodeURIComponent(url);
        return sUrls?.indexOf("http://") > -1 || sUrls?.indexOf("https://") > -1;
    }
}
