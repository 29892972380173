import {ActionCreatorDefinition, createAction} from "redux-ts";
import {BrandType} from "platform/enum/BrandType";
import {UrlType} from "platform/enum/UrlType";
import {LoaderType} from "platform/enum/LoaderType";
import {BrandProps} from "platform/redux/core/CoreReduxState";
import {OrientationType} from "platform/enum/OrientationType";
import {ThemeType} from "platform/enum/ThemeType";
import {INavigationOptions} from "platform/router/IRouter";

export const SetThemeType: string =  "SetTheme";
export const SetOrientationType: string =  "SetOrientation";
export const SetAppReadyType: string =  "SetAppReady";
export const SetExceptionType: string =  "SetException";
export const SetCookieAcceptedType: string =  "SetCookieAccepted";
export const CloseCookieNotificationType: string =  "CloseCookieNotification";
export const SetBrandType: string =  "SetBrand";
export const DoFetchBrandPropsType: string =  "DoFetchBrandProps";
export const SetBrandPropsType: string =  "SetBrandProps";
export const SetBrandPropsLoadingType: string =  "SetBrandPropsLoading";
export const SetUrlsType: string =  "SetUrls";
export const SetLoaderType: string =  "SetLoader";
export const HideLoaderType: string =  "HideLoader";
export const DisableLoaderOverlayType: string =  "DisableLoaderOverlay";
export const InitChatType: string =  "InitChat";
export const ShowChatType: string =  "ShowChat";
export const NavigateToType: string =  "NavigateTo";
export const DisplayLogsType: string =  "DisplayLogs";

export interface SetThemeTypePayload {

    themeType: ThemeType;
    persist?: boolean;
}

export interface SetOrientationPayload {

    orientation: OrientationType;
}

export interface SetAppReadyPayload {

    ready: boolean;
}

export interface SetExceptionPayload {

    exceptionType: number;
    error: Error;
}

export interface SetBrandPayload {

    brandType: BrandType;
}

export interface SetBrandPropsPayload {

    brandProps: BrandProps;
}

export interface SetBrandPropsLoadingPayload {

    loading: boolean;
}

export interface SetUrlsPayload {

    urls: {type: UrlType, url: string}[];
}

export interface SetLoaderPayload {

    loaderType: LoaderType;
}

export interface InitChatPayload {

    scriptUrl: string;
}

export interface ShowChatPayload {

    tags?: string[];
    message?: string;
    opposite?: boolean;
    comment?: string;
}

export interface NavigateToPayload {

    route: string;
    params?: any;
    opts?: INavigationOptions;
}

export interface DisplayLogsPayload {

    display: boolean;
}

export const SetTheme: ActionCreatorDefinition<SetThemeTypePayload, any> = createAction<SetThemeTypePayload>(SetThemeType);
export const SetOrientation: ActionCreatorDefinition<SetOrientationPayload, any> = createAction<SetOrientationPayload>(SetOrientationType);
export const SetAppReady: ActionCreatorDefinition<SetAppReadyPayload, any> = createAction<SetAppReadyPayload>(SetAppReadyType);
export const SetException: ActionCreatorDefinition<SetExceptionPayload, any> = createAction<SetExceptionPayload>(SetExceptionType);
export const SetCookieAccepted: ActionCreatorDefinition<any, any> = createAction<any>(SetCookieAcceptedType);
export const CloseCookieNotification: ActionCreatorDefinition<any, any> = createAction<any>(CloseCookieNotificationType);
export const SetBrand: ActionCreatorDefinition<SetBrandPayload, any> = createAction<SetBrandPayload>(SetBrandType);
export const DoFetchBrandProps: ActionCreatorDefinition<any, any> = createAction<any>(DoFetchBrandPropsType);
export const SetBrandProps: ActionCreatorDefinition<SetBrandPropsPayload, any> = createAction<SetBrandPropsPayload>(SetBrandPropsType);
export const SetBrandPropsLoading: ActionCreatorDefinition<SetBrandPropsLoadingPayload, any> = createAction<SetBrandPropsLoadingPayload>(SetBrandPropsLoadingType);
export const SetUrls: ActionCreatorDefinition<SetUrlsPayload, any> = createAction<SetUrlsPayload>(SetUrlsType);
export const SetLoader: ActionCreatorDefinition<SetLoaderPayload, any> = createAction<SetLoaderPayload>(SetLoaderType);
export const HideLoader: ActionCreatorDefinition<any, any> = createAction<any>(HideLoaderType);
export const DisableLoaderOverlay: ActionCreatorDefinition<any, any> = createAction<any>(DisableLoaderOverlayType);
export const InitChat: ActionCreatorDefinition<InitChatPayload, any> = createAction<InitChatPayload>(InitChatType);
export const ShowChat: ActionCreatorDefinition<ShowChatPayload, any> = createAction<ShowChatPayload>(ShowChatType);
export const NavigateTo: ActionCreatorDefinition<NavigateToPayload, any> = createAction<NavigateToPayload>(NavigateToType);
export const DisplayLogs: ActionCreatorDefinition<DisplayLogsPayload, any> = createAction<DisplayLogsPayload>(DisplayLogsType);
