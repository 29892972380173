enum LSKey {

    LangCode = "__lang",
    Token = "__tkn",
    Theme = "__ctm",
    ChartSearch = "__crts",
    ServerToLocalhost = "__serverToLocalhost",
    SignUpSocialSource = "__suss",
    SignUpSocialToken = "__sust",
    SignUpSocialResponse = "__susr",
}

namespace LSKey {

    export function hasKey(key: string): boolean {
        for (const sk in LSKey) {
            if (LSKey[sk] === key) {
                return true;
            }
        }
        return false;
    }
}

interface Storage {

    setItem(key: string, value: string): void;

    getItem(key: string): Promise<string | null>;

    removeItem(key: string): void;
}

export {
    LSKey, Storage
};
