import {SignUpRequest} from 'kbd/protocol/auth/SignUpRequest';
import {Engine} from "platform/engine/Engine";
import {
    ClearServerErrors,
    DoSocialLoginPayload,
    DoSubmitChangePasswordFormPayload,
    DoSubmitForgotPasswordFormPayload,
    DoSubmitLoginForm,
    DoSubmitLoginFormPayload, DoSubmitLoginTokenPayload,
    DoSubmitResetPasswordFormPayload,
    DoSubmitSignUpFormPayload,
    DoVerifyResetPasswordPayload,
    SetBoardForm,
    SetBoardFormSubmitting,
    SetChangePasswordRequired,
    SetPasswordReset,
    SetResetLinkSendToEmail,
    SetServerError,
    SetSignUpCountries,
    SetSignUpSource,
    SetTCLoginAgreement,
    SetVerificationCodeIssued,
    SetVerificationIsLimitReached,
    SetVerificationMethod,
    SetVerificationMethods,
    VerificationMethodPayload
} from "kbd/core/redux/board/BoardReduxActions";
import Platform from "platform/Platform";
import {StoreState} from "kbd/core/redux/StoreState";
import {HideLoader, NavigateTo, SetBrandPropsPayload, SetLoader, SetUrls} from "platform/redux/core/CoreActions";
import {UrlType} from "platform/enum/UrlType";
import WebUtil from "platform/util/WebUtil";
import {TSMap} from "typescript-map";
import {BoardFormType} from "kbd/enum/BoardFormType";
import {LoaderType} from "platform/enum/LoaderType";
import {FieldType} from "kbd/enum/FieldType";
import {ErrorCode} from "kbd/enum/ErrorCode";
import Utils from "platform/util/Utils";
import {Http, HttpReject} from "platform/network/http/Http";
import {Configuration, OnBoardProductConfig} from "kbd/core/configuration/Configuration";
import {LoginRequest} from "kbd/protocol/auth/LoginRequest";
import {LoginResponse} from "kbd/protocol/auth/LoginResponse";
import {SignUpResponse} from "kbd/protocol/auth/SignUpResponse";
import {ForgotPasswordRequest} from "kbd/protocol/auth/ForgotPasswordRequest";
import {ForgotPasswordResponse} from "kbd/protocol/auth/ForgotPasswordResponse";
import {FormMethod as RedirectFormMethod} from "platform/enum/FormMethod";
import SubmitForm from "platform/network/form/SubmitForm";
import {XhrManager} from "kbd/core/engine/XhrManager";
import {TokenManager} from "kbd/core/util/TokenManager";
import {LangCode} from "platform/enum/LangCode";
import {LanguageUtil} from "platform/util/LanguageUtil";
import {DpkType} from "kbd/enum/DpkType";
import {ChangeExpiredPasswordRequest} from "kbd/protocol/auth/ChangeExpiredPasswordRequest";
import {ChangeExpiredPasswordResponse} from "kbd/protocol/auth/ChangeExpiredPasswordResponse";
import {SetModal} from "kbd/core/redux/app/AppReduxActions";
import {ModalType} from "kbd/enum/ModalType";
import {LoginGetTermAndConditionsRequest} from "kbd/protocol/auth/LoginGetTermAndConditionsRequest";
import {LoginGetTermAndConditionsResponse} from "kbd/protocol/auth/LoginGetTermAndConditionsResponse";
import {LoginNotifyAgreeTCRequest} from "kbd/protocol/auth/LoginNotifyAgreeTCRequest";
import {LoginNotifyAgreeTCResponse} from "kbd/protocol/auth/LoginNotifyAgreeTCResponse";
import {ContactInfoSource} from "kbd/enum/ContactInfoSource";
import {SignUpPlatform} from "kbd/enum/SignUpPlatform";
import {SignUpResponseType} from "kbd/enum/SignUpResponseType";
import {SignUpRedirectType} from "kbd/enum/SignUpRedirectType";
import {Route} from "platform/redux/PlatformReduxState";
import {PageType} from "kbd/enum/PageType";
import {SignUpCountriesResponse, SignUpCountry} from "kbd/protocol/SignUpCountriesResponse";
import {BoardState} from "kbd/core/state/BoardState";
import {ServiceType} from "kbd/enum/ServiceType";
import {CountryInfo} from "platform/protocol/common/CountryInfo";
import {BoardUtil} from "kbd/core/util/BoardUtil";
import Parameter from "platform/util/Parameter";
import {TranslationParam} from "kbd/enum/TranslationParam";
import {BrandProp, BrandProps} from "platform/redux/core/CoreReduxState";
import Script from "platform/network/script/Script";
import {LSKey, Storage} from "platform/storage/Storage";
import {GoogleAuth, GoogleUser} from "typings";
import {ProductType} from "kbd/entry/ProductType";
import {SignUpFormState} from "kbd/core/redux/board/BoardReduxState";
import {TokenResponse} from "kbd/protocol/auth/TokenResponse";
import {VerifyResetPasswordCodeRequest} from "kbd/protocol/auth/VerifyResetPasswordCodeRequest";
import {VerifyResetPasswordCodeResponse} from "kbd/protocol/auth/VerifyResetPasswordCodeResponse";
import {ResetPasswordAndLoginRequest} from "kbd/protocol/auth/ResetPasswordAndLoginRequest";
import {ShowPopup} from "platform/redux/popups/PopupsActions";
import {PopupActionType, PopupIconType, PopupType} from "platform/redux/popups/PopupsReduxState";
import {TranslationKey} from "kbd/enum/TranslationKey";
import {GetResetPasswordVerificationCodeRequest} from "kbd/protocol/auth/GetResetPasswordVerificationCodeRequest";
import {GetResetPasswordVerificationCodeResponse} from "kbd/protocol/auth/GetResetPasswordVerificationCodeResponse";
import {MigrationDialogType} from "kbd/enum/MigrationDialogType";
import {DateTimeFormat} from "kbd/core/format/DateTimeFormat";
import {BIEventType} from "kbd/enum/BIEventType";
import {RegisterActivityRequest} from "kbd/protocol/request/RegisterActivityRequest";
import {ActivityTypeInfo} from "kbd/protocol/ActivityTypeInfo";
import {Xhr} from "platform/network/xhr/Xhr";
import {ServerType} from "platform/enum/ServerType";
import {BILoginType} from "kbd/enum/BILoginType";
import {BILoginTarget} from "kbd/enum/BILoginTarget";
import {BIUtil} from "kbd/core/util/BIUtil";
import {ConfigUtil} from "kbd/core/util/ConfigUtil";

const QueryStringRaw: string = window.location.search;
const QueryString: string = QueryStringRaw.substring(1);
const DpkParam: string = WebUtil.urlParam("dpk");
const Dpk: DpkType = DpkType.deserialize(DpkParam);
const GoogleScope: string = "https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email";
const isTnCSigningRequired: boolean = Utils.parseBoolean(WebUtil.urlParam("isTnCSigningRequired"));
const TokenTC: string = WebUtil.urlParam("token") || WebUtil.urlParam("tokenId");

export default class BoardEngine extends Engine {

    private static _instance: BoardEngine;
    private _socialSignUpInitialized: boolean;

    public static instance(): BoardEngine {
        return this._instance || (this._instance = new this());
    }

    public async setup(): Promise<void> {
        await super.setup();
        await TokenManager.token();
        if (isTnCSigningRequired) {
            this._logger.debug("Required to accept TC");
            await this.doGetTermAndConditions(null, null, TokenTC);
        }
    }

    public onChangeRoute = (payload): void => {
        const route: Route = payload.route;
        const pageType: PageType = route.name as PageType;
        const {countries} = Platform.store<StoreState>().getState().board;
        if (pageType === PageType.SignUp) {
            if (Utils.isArrayEmpty(countries)) {
                const productConfig: OnBoardProductConfig = Platform.config<Configuration>().products[ProductType.Board] as OnBoardProductConfig;
                const {countriesJsonUrl} = productConfig.signup;
                if (countriesJsonUrl) {
                    Http.getJson(countriesJsonUrl).then((response: SignUpCountriesResponse) => {
                        if (response && Utils.isArrayNotEmpty(response.countryList)) {
                            const boardState: BoardState = Platform.state(ServiceType.Board);
                            Platform.dispatch(SetSignUpCountries({
                                countries: response.countryList.map((country: SignUpCountry) => {
                                    const ci: CountryInfo = {
                                        Code: country.code,
                                        PhoneCode: country.phoneCode,
                                        LocalizedName: country.name,
                                        HideForBirthCountry: false,
                                        Id: null
                                    };
                                    boardState.addCountry(ci);
                                    return ci;
                                })
                            }));
                        }
                    }).catch(() => {
                        this._logger.warn("Failed fetch brand props");
                    });
                }
            }
            const storeState: StoreState = Platform.store().getState();
            this.initSocialSignUp(storeState.core.brandProps);
        }
    }

    public doSetBrandProps = ({brandProps}: SetBrandPropsPayload): void => {
        const storeState: StoreState = Platform.store().getState();
        if (storeState.router.route.name === PageType.SignUp) {
            this.initSocialSignUp(brandProps);
        }
    }

    private initSocialSignUp = (brandProps: BrandProps): void => {
        if (!Utils.isObjectEmpty(brandProps.keys) && !this._socialSignUpInitialized) {
            this._logger.debug("Init social SignUp");
            this._socialSignUpInitialized = true;
            // Initialize facebook api
            const facebook: BrandProp = brandProps.keys.nxReg_Facebook;
            if (facebook && Utils.parseBoolean(facebook.value)) {
                const facebookAppId: BrandProp = brandProps.keys.nxReg_FacebookAppId;
                if (WebUtil.isMobile()) {
                    this._logger.debug("Set facebook signUp redirect url");
                    const backUrl: string = encodeURI(window.location.protocol + "//" + window.location.hostname + window.location.pathname);
                    const facebookSignUpUrl: string = WebUtil.addGetParams("https://www.facebook.com/v7.0/dialog/oauth", {
                        client_id: facebookAppId ? facebookAppId.value : "",
                        redirect_uri: backUrl,
                        response_type: "token",
                        scope: "public_profile,email",
                        state: JSON.stringify({
                            source: "facebook"
                        })
                    });
                    Platform.dispatch(SetUrls({urls: [
                            {type: UrlType.FacebookSignUp, url: facebookSignUpUrl}
                        ]}));
                } else {
                    this._logger.debug("Injecting facebook api script");
                    window.fbAsyncInit = (): void => {
                        this._logger.debug("Facebook initializing with app Id");
                        window.FB.init({
                            appId: facebookAppId ? facebookAppId.value : "", // {your-app-id}
                            cookie: true,
                            xfbml: true,
                            version: 'v7.0' // {api-version}
                        });
                        window.FB.AppEvents.logPageView();

                    };
                    Script.injectScript("https://connect.facebook.net/en_US/sdk.js", "facebook-jssdk");
                }
            } else {
                this._logger.debug("Facebook SignUp not configured");
            }
            // Initialize google api
            const google: BrandProp = brandProps.keys.nxReg_Google;
            if (Utils.parseBoolean(google && google.value)) {
                const googleClientId: BrandProp = brandProps.keys.nxReg_GoogleClientId;
                if (WebUtil.isMobile()) {
                    this._logger.debug("Set google signUp redirect url");
                    const backUrl: string = encodeURI(window.location.protocol + "//" + window.location.hostname + window.location.pathname);
                    const googleSignUpUrl: string = WebUtil.addGetParams("https://accounts.google.com/o/oauth2/v2/auth", {
                        scope: encodeURI(GoogleScope),
                        include_granted_scopes: "true",
                        state: JSON.stringify({
                            source: "google"
                        }),
                        redirect_uri: backUrl,
                        response_type: "token",
                        client_id: googleClientId ? googleClientId.value : ""
                    });
                    Platform.dispatch(SetUrls({urls: [
                            {type: UrlType.GoogleSignUp, url: googleSignUpUrl}
                        ]}));
                } else {
                    this._logger.debug("Injecting google api script");
                    window.googleAuth = {};
                    Script.injectScript("https://apis.google.com/js/api.js", "google-api", () => {
                        this._logger.debug("Google gapi loading");
                        window.gapi.load("client:auth2", () => {
                            this._logger.debug("Google init api client");
                            const googleApiKey: BrandProp = brandProps.keys.nxReg_GoogleApiKey;
                            window.gapi.client.init({
                                apiKey: googleApiKey ? googleApiKey.value : "",
                                clientId: googleClientId ? googleClientId.value : "",
                                discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/drive/v3/rest'],
                                scope: GoogleScope
                            }).then(() => {
                                this._logger.debug("GoogleAuth ready");
                                window.googleAuth = window.gapi.auth2.getAuthInstance();
                                window.googleAuth.isSignedIn.listen(this.onGoogleAuthUser);
                            }).catch((e) => {
                                this._logger.warn("Failed init google api: " + e.details);
                            });
                        });
                    });
                }
            } else {
                this._logger.debug("Google SignUp not configured");
            }
        }
    }

    private onGoogleAuthUser = (): void => {
        this._logger.debug("On GoogleAuth user");
        const googleAuth: GoogleAuth = window.googleAuth;
        const user: GoogleUser = googleAuth.currentUser.get();
        const isAuthorized: boolean = user.hasGrantedScopes(GoogleScope);
        if (isAuthorized) {
            Platform.dispatch(SetSignUpSource({signUpSource: ContactInfoSource.Google}));
            const storage: Storage = Platform.environment().sessionStorage();
            storage.setItem(LSKey.SignUpSocialSource, ContactInfoSource.Google);
            storage.setItem(LSKey.SignUpSocialToken, user.getAuthResponse().access_token);
            storage.setItem(LSKey.SignUpSocialResponse, JSON.stringify(user.getAuthResponse()));
        } else {
            Platform.dispatch(SetServerError({
                fieldType: FieldType.SignUpPassword,
                error: {errorCode: ErrorCode.SIGN_UP_ERROR_GOOGLE}
            }));
        }
    }

    private getSocialLoginUrl = ({socialSource, formType}: DoSocialLoginPayload): string => {
        const urls: TSMap<UrlType, string> = Platform.reduxState<StoreState>().core.urls;
        if (formType === BoardFormType.SignUp) {
            return urls.get(socialSource === ContactInfoSource.Google ? UrlType.GoogleSignUp : UrlType.FacebookSignUp);
        } else {
            let url: string = urls.get(socialSource === ContactInfoSource.Google ? UrlType.GoogleLogin : UrlType.FacebookLogin);
            const params: { [key: string]: string } = WebUtil.getParamsToObject(url);
            if (params.loginuri) {
                params.loginuri = ConfigUtil.UseAddressBarDomain(params.loginuri);
                if (Platform.config<Configuration>().useAddressBarDomain) {
                    const parts: string[] = window.location.hostname?.split(".");
                    params.usedomain = `${parts[parts.length - 2]}.${parts[parts.length - 1]}`
                }
                if (Utils.isNotEmpty(DpkParam)) {
                    params.dpk = encodeURIComponent(QueryString);
                    params.loginuri = WebUtil.addGetParam(params.loginuri, "dpk", DpkParam);
                }
            }
            if (params.reguri?.indexOf("qaplexop") < 0) {
                params.reguri = ConfigUtil.UseAddressBarDomain(params.reguri);
            }
            url = WebUtil.formatUrl(url, params);
            return url;
        }
    }

    public doSocialLogin = async (payload: DoSocialLoginPayload) => {
        const {socialSource, formType} = payload;
        this._logger.debug("Do social login with: " + socialSource);
        Platform.dispatch(ClearServerErrors({}));
        const url: string = this.getSocialLoginUrl(payload);
        if (formType === BoardFormType.SignUp) {
            const storage: Storage = Platform.environment().sessionStorage();
            const signUpSource: ContactInfoSource = await storage.getItem(LSKey.SignUpSocialSource) as ContactInfoSource;
            const signUpToken: string = await storage.getItem(LSKey.SignUpSocialToken);
            const signUpResponse: string = await storage.getItem(LSKey.SignUpSocialResponse);
            if (ContactInfoSource.isSocial(signUpSource) && socialSource === signUpSource && Utils.isNotEmpty(signUpToken) && Utils.isNotEmpty(signUpResponse)) {
                Platform.dispatch(SetSignUpSource({signUpSource}));
            } else {
                if (WebUtil.isMobile()) {
                    if (url) {
                        Platform.environment().redirect(url);
                    } else {
                        this._logger.warn("Can't redirect go empty social sign up url with type: " + socialSource);
                    }
                } else {
                    if (socialSource === ContactInfoSource.Facebook) {
                        this._logger.debug("Try SignUp with facebook");
                        Platform.dispatch(SetLoader({loaderType: LoaderType.FullScreen}));
                        window.FB.login((response): void => {
                            this._logger.debug("Facebook sign up status: " + response.status);
                            Platform.dispatch(HideLoader({}));
                            if (response.status === "connected") {
                                Platform.dispatch(SetSignUpSource({signUpSource: ContactInfoSource.Facebook}));
                                storage.setItem(LSKey.SignUpSocialSource, ContactInfoSource.Facebook);
                                storage.setItem(LSKey.SignUpSocialToken, response.authResponse.accessToken);
                                storage.setItem(LSKey.SignUpSocialResponse, JSON.stringify(response));
                            } else {
                                Platform.dispatch(SetServerError({
                                    fieldType: FieldType.SignUpPassword,
                                    error: {errorCode: ErrorCode.SIGN_UP_ERROR_FACEBOOK}
                                }));
                            }
                        }, { scope: "public_profile,email" });
                    } else if (socialSource === ContactInfoSource.Google) {
                        const googleAuth: GoogleAuth = window.googleAuth;
                        if (googleAuth.isSignedIn && googleAuth.isSignedIn.get()) {
                            this._logger.debug("Already has Google user");
                            this.onGoogleAuthUser();
                        } else if (googleAuth.signIn) {
                            Platform.dispatch(SetLoader({loaderType: LoaderType.FullScreen}));
                            this._logger.debug("Google signing in");
                            googleAuth.signIn().then(() => {
                                Platform.dispatch(HideLoader({}));
                                this.onGoogleAuthUser();
                            }, () => {
                                Platform.dispatch(HideLoader({}));
                                this._logger.warn("Failed google signIn");
                                Platform.dispatch(SetServerError({
                                    fieldType: FieldType.SignUpPassword,
                                    error: {errorCode: ErrorCode.SIGN_UP_ERROR_GOOGLE}
                                }));
                            });
                        } else {
                            this._logger.warn("GoogleAuth object empty");
                        }
                    }
                }
            }
        } else {
            if (url) {
                const {brand, version} = Platform.config();
                Platform.bi().track(BIEventType.LoginAttempt, {
                    Brand: brand,
                    LoginType: BILoginType.Social,
                    SocialLogin: socialSource,
                    Platform: BIUtil.Platform()
                });
                const {migrationDialogType} = Platform.reduxState<StoreState>().board;
                if (migrationDialogType === MigrationDialogType.Fork) {
                    Platform.dispatch(SetModal({
                        modalType: ModalType.SoftLaunch,
                        visible: true,
                        parameters: [
                            Parameter.Of("Profit", () => {
                                Platform.environment().redirect(WebUtil.addGetParam(url, "ExtraParameters", encodeURIComponent(
                                    JSON.stringify({TradingPlatform: 0, applicationVersion: `${brand} ${version}`})
                                )));
                            }),
                            Parameter.Of("XCite", () => {
                                Platform.environment().redirect(WebUtil.addGetParam(url, "ExtraParameters", encodeURIComponent(
                                    JSON.stringify({TradingPlatform: 1, applicationVersion: `${brand} ${version}`})
                                )));
                            })
                        ]
                    }));
                } else {
                    Platform.environment().redirect(WebUtil.addGetParam(url, "ExtraParameters", encodeURIComponent(
                        JSON.stringify({applicationVersion: `${brand} ${version}`})
                    )));
                }
            } else {
                this._logger.warn("Can't open empty social login url with type: " + socialSource);
            }
        }
    }

    public doSubmitSignUpForm = async (payload: DoSubmitSignUpFormPayload) => {
        await this.DoSubmitSignUpForm(payload.form);
    }

    public doResendEmail = async () => {
        const {form} = Platform.store<StoreState>().getState().board;
        this._logger.debug("Resend email on: " + form.email);
        await this.DoSubmitSignUpForm(form);
    }

    private DoSubmitSignUpForm = async ({FullName, email, referralCode, signUpPassword, signUpCountryCode, PhoneNumber}: SignUpFormState) => {
        const {signUpSource} = Platform.reduxState<StoreState>().board;
        const isSocialSignUp: boolean = ContactInfoSource.isSocial(signUpSource);
        const productConfig: OnBoardProductConfig = Platform.config<Configuration>().products[ProductType.Board] as OnBoardProductConfig;
        const {brandId, funnelId} = Platform.config<Configuration>();
        const {verificationEmailEnabled, AdvertiserId, AgencyId, PrivacyNotice, TermsAndConditions, ReCaptcha, ReCaptchaKey} = productConfig.signup;
        const boardState: BoardState = Platform.state(ServiceType.Board);
        const ci: CountryInfo = boardState.getCountryByPhoneCode(signUpCountryCode);
        this._logger.debug("Do sign up with: " + email);
        let ReCaptchaToken: string = "white_listed_code";
        if (ReCaptcha && Utils.isNotEmpty(ReCaptchaKey)) {
            const cAnswer: [any, string] = await Utils.to(BoardUtil.generateCaptchaToken(ReCaptchaKey));
            if (cAnswer[1]) {
                ReCaptchaToken = cAnswer[1];
            }
        }
        let SocialRawData: string;
        let Token: string;
        if (isSocialSignUp) {
            const storage: Storage = Platform.environment().sessionStorage();
            SocialRawData = await storage.getItem(LSKey.SignUpSocialResponse);
            Token = await storage.getItem(LSKey.SignUpSocialToken);
        }
        const request: SignUpRequest = {
            AdvertiserId,
            AgencyId,
            BrandId: brandId,
            LabelId: null,
            ContactInfoSource: signUpSource,
            CountryCode: ci?.Code,
            Email: email,
            EventId: Utils.uuid(),
            FormId: null,
            FullName,
            FunnelId: funnelId,
            IsMobile: WebUtil.isMobile(),
            IsTest: 1, // 1 - true
            MobilePhone: ci ? "+" + signUpCountryCode + PhoneNumber : null,
            Password: signUpPassword,
            Platform: SignUpPlatform.Profit,
            PrivacyNotice,
            QueryString,
            ReCaptchaToken,
            SkipGeoValidation: null,
            SkipEmailValidation: !verificationEmailEnabled,
            TermsAndConditions,
            VerificationCode: referralCode,
            SocialRawData,
            Token
        };
        Platform.dispatch(SetLoader({loaderType: LoaderType.FullScreen}));
        const answer: [HttpReject, SignUpResponse] = await Utils.to(XhrManager.sendToRegister(request, "register"));
        Platform.dispatch(SetBoardFormSubmitting({submitting: false}));
        Platform.dispatch(HideLoader({}));

        if (answer[0]) {
            this._logger.debug("Failed Sign Up");
            Platform.dispatch(SetServerError({
                fieldType: FieldType.SignUpPassword,
                error: {errorCode: ErrorCode.GeneralError}
            }));
        } else {
            Platform.dispatch(ClearServerErrors({}));
            const response: SignUpResponse = answer[1];
            const errorCode: ErrorCode = parseInt("10" + response.error_code, 10);
            if (response.success) {
                if (response.response_type === SignUpResponseType.ActivationEmailSent) {
                    // TODO Report pixel
                    Platform.dispatch(NavigateTo({route: PageType.CheckInbox}));
                } else if (response.redirect_type === SignUpRedirectType.Client) {
                    if (response.is_first) {
                        // TODO Report pixel
                        Platform.environment().redirect(response.redirect_url);
                    } else {
                        if (isSocialSignUp) {
                            window.open(decodeURIComponent(response.redirect_url), "_top");
                        } else {
                            Platform.dispatch(SetServerError({
                                fieldType: FieldType.SignUpPassword,
                                error: {
                                    errorCode: ErrorCode.EMAIL_ADDRESS_ALREADY_EXIST,
                                    params: [Parameter.Of(TranslationParam.url, "/login")]
                                }
                            }));
                        }
                    }
                } else if (response.redirect_type === SignUpRedirectType.ThankYouPage) {
                    // TODO Report pixel
                    // TODO Show activation thank you page
                } else if (response.redirect_type === SignUpRedirectType.AppsFlyer) {
                    // TODO Report pixel
                    Platform.environment().redirect(response.redirect_url);
                } else {
                    Platform.dispatch(SetServerError({
                        fieldType: FieldType.SignUpPassword,
                        error: {errorCode: ErrorCode.GeneralError}
                    }));
                }
            } else {
                if (response.error_code) {
                    if (isSocialSignUp && (errorCode === ErrorCode.DUPLICATE_LEAD || errorCode === ErrorCode.DUPLICATE_EID)) {
                        window.open(decodeURIComponent(response.redirect_url), "_top");
                    } else {
                        Platform.dispatch(SetServerError({
                            fieldType: FieldType.SignUpPassword,
                            error: {errorCode}
                        }));
                    }
                } else {
                    if (response.redirect_url) {
                        window.open(decodeURIComponent(response.redirect_url), "_top");
                    } else {
                        Platform.dispatch(SetServerError({
                            fieldType: FieldType.SignUpPassword,
                            error: {errorCode: ErrorCode.GeneralError}
                        }));
                    }
                }
            }
        }
    }

    public doSubmitLoginToken = async ({token}: DoSubmitLoginTokenPayload) => {
        this._logger.debug("Do login with token: " + token);
        const {brand} = Platform.config();
        const useToken: string = token?.replace(/\s/g, "");
        Platform.bi().track(BIEventType.LoginAttempt, {
            Brand: brand,
            LoginType: BILoginType.Token,
            Platform: BIUtil.Platform()
        });
        Platform.dispatch(SetLoader({loaderType: LoaderType.FullScreen}));
        setTimeout(() => {
            Platform.dispatch(HideLoader({}));
            Platform.environment().redirect("https://static-plexop.s3.amazonaws.com/temp/images/PlatformUnderConstruction.html");
        }, 2000);
    }

    public doSubmitLoginForm = async (payload: DoSubmitLoginFormPayload) => {
        const {username, loginPassword} = payload.form;
        this._logger.debug("Do login with: " + username + " dpk: " + Dpk);
        const langCode: LangCode = LanguageUtil.languageCode();
        const {brand, version} = Platform.config();
        const isDeposit: boolean = Dpk === DpkType.Deposit;
        const loginRequest: LoginRequest = {
            Username: username,
            Password: loginPassword,
            Token: isTnCSigningRequired ? TokenTC : null,
            BrandId: Platform.config<Configuration>().brandId,
            LanguageCode: langCode,
            isDeposit,
            ApplicationVersion: `${brand} ${version}`
        };
        const BiLoginData = {
            Brand: brand,
            LoginType: BILoginType.Regular,
            Platform: BIUtil.Platform()
        };
        Platform.bi().track(BIEventType.LoginAttempt, BiLoginData);
        Platform.dispatch(SetLoader({loaderType: LoaderType.FullScreen}));
        const answer: [HttpReject, LoginResponse] = await Utils.to(XhrManager.sendToLogin(loginRequest, "Login"));
        Platform.dispatch(HideLoader({}));
        Platform.dispatch(SetBoardFormSubmitting({submitting: false}));
        if (answer[0]) {
            this._logger.debug("Failed login");
            Platform.bi().track(BIEventType.LoginDecision, {
                ...BiLoginData,
                Target: BILoginTarget.LoginError,
                ServerMessage: "Server not respond"
            });
            Platform.dispatch(SetServerError({
                fieldType: FieldType.LoginPassword,
                error: {errorCode: ErrorCode.GeneralError}
            }));
        } else {
            const response: LoginResponse = answer[1];
            Platform.dispatch(ClearServerErrors({}));
            Platform.dispatch(SetChangePasswordRequired({required: response.ChangePasswordRequired}));
            if (response.ChangePasswordRequired) {
                Platform.bi().track(BIEventType.LoginDecision, {
                    ...BiLoginData,
                    Target: BILoginTarget.ChangeExpiredPassword,
                });
                Platform.dispatch(SetBoardForm({
                    form: {email: username}
                }));
            }
            if (Utils.isArrayNotEmpty(response.AvailableChannels)) {
                Platform.dispatch(SetVerificationMethods({methods: response.AvailableChannels}));
            }
            if (response.TnCSigningRequired) {
                this._logger.debug("Required to accept TC");
                Platform.bi().track(BIEventType.LoginDecision, {
                    ...BiLoginData,
                    Target: BILoginTarget.TCSignRequired,
                });
                await this.doGetTermAndConditions(username, loginPassword, null);
            } else {
                if (response.SuccessStatus) {
                    this._logger.debug("Successful login with userId: " + response.UserId);
                    this.navigateTo(response);
                } else {
                    this._logger.debug("Failed login with username: " + username);
                    const ServerMessage: string = response.LocalizedErrorMessage || response.ErrorMessage;
                    Platform.bi().track(BIEventType.LoginDecision, {
                        ...BiLoginData,
                        Target: BILoginTarget.LoginError,
                        ServerMessage
                    });
                    Platform.dispatch(SetServerError({
                        fieldType: FieldType.LoginPassword,
                        error: {
                            errorCode: ErrorCode.InvalidUsernameOrPassword,
                            errorMessage: ServerMessage
                        }
                    }));
                }
            }
        }
    }

    private doGetTermAndConditions = async (Username: string, Password: string, token: string) => {
        this._logger.debug("Fetch login TC for: " + Username);
        const request: LoginGetTermAndConditionsRequest = {
            BrandId: Platform.config<Configuration>().brandId,
            Username,
            Password,
            Token: token
        };
        const answer: [HttpReject, LoginGetTermAndConditionsResponse] = await Utils.to(XhrManager.sendToLoginTC(request, "GetTermAndConditions"));
        if (answer[0]) {
            this._logger.debug("Failed fetch TC");
            Platform.dispatch(SetServerError({
                fieldType: FieldType.LoginPassword,
                error: {errorCode: ErrorCode.GeneralError}
            }));
        } else {
            const {Id, SummaryURL} = answer[1];
            if (Utils.greaterThen0(Id) && Utils.isNotEmpty(SummaryURL)) {
                Platform.dispatch(SetBoardFormSubmitting({submitting: false}));
                Platform.dispatch(SetTCLoginAgreement({
                    tcAgreement: {
                        username: Username,
                        password: Password,
                        id: Id,
                        contentUrl: SummaryURL
                    }
                }));
                Platform.dispatch(SetModal({
                    modalType: ModalType.LoginTC,
                    visible: true
                }));
            } else {
                this._logger.debug("Can't show Login TC modal. URL or Id missing in response");
                Platform.dispatch(SetServerError({
                    fieldType: FieldType.LoginPassword,
                    error: {errorCode: ErrorCode.GeneralError}
                }));
            }
        }
    }

    public doLoginNotifyUserAgreement = async () => {
        const {id, username, password} = Platform.reduxState<StoreState>().board.tcAgreement;
        this._logger.debug("Notify login agreed with TC for: " + username);
        const request: LoginNotifyAgreeTCRequest = {
            Id: id,
            BrandId: Platform.config<Configuration>().brandId,
            Username: username,
            Password: password,
            Token: isTnCSigningRequired ? TokenTC : null
        };
        const answer: [HttpReject, LoginNotifyAgreeTCResponse] = await Utils.to(XhrManager.sendToLoginTC(request, "NotifyUserAgreement"));
        if (answer[0]) {
            this._logger.debug("Failed login agreed with TC");
            Platform.dispatch(SetServerError({
                fieldType: FieldType.LoginPassword,
                error: {errorCode: ErrorCode.GeneralError}
            }));
        } else {
            Platform.dispatch(DoSubmitLoginForm({
                form: {
                    username,
                    loginPassword: password
                }
            }));
        }
    }

    private navigateTo = ({UserId, RedirectURL, RedirectURLOldPlatform, RedirectURLNewPlatform, RegistrationDate, FormMethod, Token, CesLeadToken, IsRealAccount}: TokenResponse): void => {
        const {langCode} = Platform.reduxState().translation;
        const {brand} = Platform.config();
        const isDeposit: boolean = Dpk === DpkType.Deposit;
        Platform.bi().set({
            UserId,
            realAccount: IsRealAccount
        });
        const submit = (url: string, method: RedirectFormMethod) => {
            Platform.bi().track(BIEventType.LoginDecision, {
                Brand: brand,
                LoginType: BILoginType.Regular,
                Platform: BIUtil.Platform(),
                Target: isDeposit ? BILoginTarget.Deposit : BILoginTarget.Platform,
            });
            let parameters: { [key: string]: string } = {
                "token": Token,
                "language": langCode,
                CesLeadToken
            };
            const targetUrl: string = ConfigUtil.UseAddressBarDomain(url);
            if (method === RedirectFormMethod.POST) {
                parameters.dpk = QueryString;
                SubmitForm.post(targetUrl, parameters);
            } else {
                if (DpkParam) {
                    parameters = {...parameters, ...WebUtil.getParamsToObject(QueryStringRaw)};
                }
                Platform.environment().redirect(WebUtil.addGetParams(targetUrl, parameters));
            }
        };
        const {migrationDialogType, forkSignUpThreshold} = Platform.reduxState<StoreState>().board;
        if (migrationDialogType === MigrationDialogType.Fork && Dpk !== DpkType.Deposit) {
            if (Utils.isEmpty(forkSignUpThreshold) || !Utils.greaterThen0(RegistrationDate) || DateTimeFormat.parseDate(forkSignUpThreshold).isAfter(DateTimeFormat.Of(RegistrationDate))) {
                Platform.dispatch(SetModal({
                    modalType: ModalType.SoftLaunch,
                    visible: true,
                    parameters: [
                        Parameter.Of("Profit", () => {
                            Platform.dispatch(SetLoader({loaderType: LoaderType.FullScreen}));
                            submit(RedirectURLOldPlatform, RedirectFormMethod.POST);
                        }),
                        Parameter.Of("XCite", () => {
                            const {brand, version} = Platform.config();
                            const request: RegisterActivityRequest = {
                                ActivityType: ActivityTypeInfo.CustomActivity,
                                Details: `${brand} ${version}`,
                                Comments: `${brand} ${version}`
                            };
                            Xhr.sendTo(ServerType.Compliance, request, "ComplianceWebsite/ComplianceFormService.svc/json/RegisterActivity", Token).catch(() => {
                                this._logger.debug("Failed register activity: " + ActivityTypeInfo.CustomActivity);
                            });
                            Platform.dispatch(SetLoader({loaderType: LoaderType.FullScreen}));
                            submit(RedirectURLNewPlatform, RedirectFormMethod.GET);
                        })
                    ]
                }));
            } else {
                submit(RedirectURLNewPlatform, RedirectFormMethod.GET);
            }
        } else {
            submit(RedirectURL, FormMethod);
        }
    }

    public doSubmitForgotPasswordForm = async ({form}: DoSubmitForgotPasswordFormPayload) => {
        const {email} = form;
        this._logger.debug("Perform forgot password for: " + email);
        const brandId: number = Platform.config<Configuration>().brandId;
        const request: ForgotPasswordRequest = {UserName: email, BrandId: brandId};
        Platform.dispatch(SetLoader({loaderType: LoaderType.FullScreen}));
        const answer: [HttpReject, ForgotPasswordResponse] = await Utils.to(XhrManager.sendToLogin(request, "ForgetPassword"));
        Platform.dispatch(HideLoader({}));
        Platform.dispatch(SetBoardFormSubmitting({submitting: false}));
        if (answer[0]) {
            this._logger.debug("Failed forgot password");
            Platform.dispatch(SetResetLinkSendToEmail({sent: false}));
            Platform.dispatch(ShowPopup({popup: {
                    type: PopupType.ERROR,
                    icon: {type: PopupIconType.ERROR},
                    title: {trKey: TranslationKey.oops},
                    message: {trKey: TranslationKey.serverErrorGeneral},
                    showClose: true,
                    actions: [{type: PopupActionType.OK}]
                }}));
        } else {
            const {SuccessStatus, SentChannelType, AvailableChannels} = answer[1];
            this._logger.debug(`Forgot password verification code. Sent channel ${SentChannelType}. Status ${SuccessStatus}`);
            if (!SuccessStatus) {
                Platform.dispatch(SetServerError({
                    fieldType: FieldType.Email,
                    error: {
                        errorCode: ErrorCode.EmailAddressNotFound
                    }
                }));
            } else {
                this._logger.debug("Successful forgot password");
                if (SentChannelType) {
                    Platform.dispatch(SetResetLinkSendToEmail({sent: true}));
                    Platform.dispatch(SetVerificationMethods({methods: AvailableChannels}));
                    Platform.dispatch(ClearServerErrors({}));
                    Platform.dispatch(NavigateTo({route: PageType.Verification}));
                } else {
                    Platform.dispatch(ShowPopup({popup: {
                            type: PopupType.ERROR,
                            icon: {type: PopupIconType.ERROR},
                            title: {trKey: TranslationKey.oops},
                            message: {trKey: TranslationKey.serverErrorGeneral},
                            showClose: true,
                            actions: [{type: PopupActionType.OK}]
                        }}));
                }
            }
        }
    }

    public onGetResetPasswordVerificationCode = async ({method, ResendVerificationCode}: VerificationMethodPayload) => {
        const {email} = Platform.reduxState<StoreState>().board.form;
        const {name: routerName} = Platform.reduxState<StoreState>().router.previousRoute;
        this._logger.debug("Change verification method to: " + method);
        const brandId: number = Platform.config<Configuration>().brandId;
        const request: GetResetPasswordVerificationCodeRequest = {
            email,
            brandId,
            SendChannelType: method,
            ResendVerificationCode,
            Source: "ForgetPassword"
        };
        Platform.dispatch(SetVerificationCodeIssued({
            issued: false
        }));
        Platform.dispatch(SetLoader({loaderType: LoaderType.FullScreen}));
        const answer: [HttpReject, GetResetPasswordVerificationCodeResponse] = await Utils.to(XhrManager.sendToLogin(request, "GetResetPasswordVerificationCode"));
        Platform.dispatch(HideLoader({}));
        if (answer[0] || !answer[1]?.Success) {
            this._logger.debug("Failed change verification method");
            Platform.dispatch(ShowPopup({popup: {
                    type: PopupType.ERROR,
                    icon: {type: PopupIconType.ERROR},
                    title: {trKey: TranslationKey.oops},
                    message: {trKey: TranslationKey.serverErrorGeneral},
                    showClose: true,
                    actions: [{type: PopupActionType.OK}]
                }}));
        } else {
            const {AvailableChannels, IsLimitReached} = answer[1];
            Platform.dispatch(SetVerificationMethod({method}));
            Platform.dispatch(SetVerificationMethods({methods: AvailableChannels}));
            Platform.dispatch(SetVerificationCodeIssued({
                issued: true
            }));
            Platform.dispatch(SetVerificationIsLimitReached({IsLimitReached}));
        }
    }

    public doVerifyResetPassword = async ({form}: DoVerifyResetPasswordPayload) => {
        const {code} = form;
        const {email} = Platform.reduxState<StoreState>().board.form;
        this._logger.debug("Ask verify code for: ", email, " code: ", code);
        const request: VerifyResetPasswordCodeRequest = {
            email,
            code,
            brandId: Platform.config<Configuration>().brandId,
        };
        Platform.dispatch(SetLoader({loaderType: LoaderType.FullScreen}));
        const answer: [HttpReject, VerifyResetPasswordCodeResponse] = await Utils.to(XhrManager.sendToLogin(request, "VerifyResetPasswordCode"));
        Platform.dispatch(HideLoader({}));
        Platform.dispatch(SetBoardFormSubmitting({submitting: false}));
        if (answer[0]) {
            Platform.dispatch(ShowPopup({popup: {
                    type: PopupType.ERROR,
                    icon: {type: PopupIconType.ERROR},
                    title: {trKey: TranslationKey.oops},
                    message: {trKey: TranslationKey.serverErrorGeneral},
                    showClose: true,
                    actions: [{type: PopupActionType.OK}]
                }}));
        } else {
            const {Success, LocalizeMessage} = answer[1];
            if (!Success) {
                Platform.dispatch(SetServerError({
                    fieldType: FieldType.VerificationCode,
                    error: {
                        errorCode: ErrorCode.GeneralError,
                        errorMessage: LocalizeMessage
                    }
                }));
            } else {
                Platform.dispatch(NavigateTo({route: PageType.ResetPassword}));
            }
        }
    }

    public doSubmitResetPasswordForm = async ({form}: DoSubmitResetPasswordFormPayload) => {
        const {newPassword, confirmPassword} = form;
        if (newPassword === confirmPassword) {
            const {brand, version} = Platform.config();
            const {email, code} = Platform.reduxState<StoreState>().board.form;
            this._logger.debug(`Perform reset password. Email ${email}, code ${code}`);
            const request: ResetPasswordAndLoginRequest = {
                email,
                code,
                newPassword,
                brandId: Platform.config<Configuration>().brandId,
                ApplicationVersion: `${brand} ${version}`
            };
            Platform.dispatch(SetLoader({loaderType: LoaderType.FullScreen}));
            const answer: [HttpReject, LoginResponse] = await Utils.to(XhrManager.sendToLogin(request, "ResetPasswordAndLogin"));
            Platform.dispatch(HideLoader({}));
            Platform.dispatch(SetBoardFormSubmitting({submitting: false}));
            if (answer[0]) {
                this._logger.debug("Failed reset password");
                Platform.dispatch(SetPasswordReset({reset: false}));
                Platform.dispatch(ShowPopup({popup: {
                        type: PopupType.ERROR,
                        icon: {type: PopupIconType.ERROR},
                        title: {trKey: TranslationKey.oops},
                        message: {trKey: TranslationKey.serverErrorGeneral},
                        showClose: true,
                        actions: [{type: PopupActionType.OK}]
                    }}));
            } else {
                const response: LoginResponse = answer[1];
                if (response.SuccessStatus) {
                    this._logger.debug("Successful login after reset password with userId: " + response.UserId);
                    Platform.dispatch(SetPasswordReset({reset: true}));
                    Platform.dispatch(ClearServerErrors({}));
                    this.navigateTo(response);
                } else {
                    Platform.dispatch(SetPasswordReset({reset: false}));
                    Platform.dispatch(SetServerError({
                        fieldType: FieldType.ConfirmPassword,
                        error: {errorMessage: response.LocalizedErrorMessage || response.ErrorMessage}
                    }));
                }
            }
        } else {
            this._logger.debug("Passwords do not match");
            Platform.dispatch(SetPasswordReset({reset: false}));
            Platform.dispatch(SetServerError({
                fieldType: FieldType.ConfirmPassword,
                error: {errorCode: ErrorCode.PasswordsNotMatch}
            }));
        }
    }

    public doSubmitChangePasswordForm = async (payload: DoSubmitChangePasswordFormPayload) => {
        const {username, loginPassword, newPassword, confirmPassword} = payload.form;
        if (newPassword === confirmPassword) {
            this._logger.debug("Perform change password. Dpk: " + Dpk);
            const langCode: LangCode = LanguageUtil.languageCode();
            const request: ChangeExpiredPasswordRequest = {
                BrandId: Platform.config<Configuration>().brandId,
                LanguageCode: langCode,
                OldPassword: loginPassword,
                NewPassword: newPassword,
                Username: username,
                isDeposit: Dpk === DpkType.Deposit
            };
            Platform.dispatch(SetLoader({loaderType: LoaderType.FullScreen}));
            const answer: [HttpReject, ChangeExpiredPasswordResponse] = await Utils.to(XhrManager.sendToLogin(request, "ChangeExpiredPassword"));
            Platform.dispatch(HideLoader({}));
            Platform.dispatch(SetBoardFormSubmitting({submitting: false}));
            if (answer[0]) {
                this._logger.debug("Failed change password");
                Platform.dispatch(SetServerError({
                    fieldType: FieldType.ConfirmPassword,
                    error: {errorMessage: XhrManager.fetchRejectReason(answer), errorCode: ErrorCode.GeneralError}
                }));
            } else {
                const response: ChangeExpiredPasswordResponse = answer[1];
                if (response.SuccessStatus) {
                    this._logger.debug("Successful changed password for user: " + username);
                    Platform.dispatch(ClearServerErrors({}));
                    this.navigateTo(response);
                } else {
                    this._logger.debug("Failed change password with username: " + username);
                    Platform.dispatch(SetServerError({
                        fieldType: FieldType.ConfirmPassword,
                        error: {
                            errorCode: ErrorCode.InvalidUsernameOrPassword,
                            errorMessage: response.LocalizedErrorMessage || response.ErrorMessage
                        }
                    }));
                }
            }
        } else {
            this._logger.debug("Passwords do not match");
            Platform.dispatch(SetServerError({
                fieldType: FieldType.ConfirmPassword,
                error: {errorCode: ErrorCode.PasswordsNotMatch}
            }));
        }
    }
}
