import {TranslationUnit} from "platform/translation/TranslationUnit";
import Parameter from "platform/util/Parameter";

export interface PopupsReduxState {

    popups: Popup[];
}

export enum PopupType {

    INFO,
    CONFIRM,
    ERROR,
    SUCCESS
}

export enum PopupActionType {
    OK,
    CANCEL
}

export enum PopupIconType {

    SUCCESS = "success",
    ERROR = "error",
    CONFIRM = "confirm",
    INFO = "info",
}

export interface PopupAction {

    type: PopupActionType;
    text?: TranslationUnit;
    textColor?: string;
    backgroundColor?: string;
    action?: () => void;
    parameters?: Parameter[];
}

export interface PopupBody {

    url?: string;
    backgroundColor?: string;
    extra?: JSX.Element;
    hyperlink?: string;
}

export interface PopupIcon {

    type?: PopupIconType;
    clazzWrapper?: string;
    clazz?: string;
    url?: string;
    base64?: string;
}

export interface Popup {

    id?: number;
    remoteId?: number;
    type: PopupType;
    ttl?: number; // time to live in seconds
    clazz?: string;
    showClose?: boolean;
    closeOnOutsideClick?: boolean;
    icon?: PopupIcon;
    title?: TranslationUnit;
    titleColor?: string;
    message?: TranslationUnit;
    body?: PopupBody;
    actions?: PopupAction[];
}
